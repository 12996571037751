<template>
           
 <!-- web navigation start-->

 <div v-if="!isMobile">
  <desktop>
 
    <v-div>

      <Navigation/>

    </v-div>
  </desktop>
</div>

    
    <landingpage/>
  

 
    </template>
    
    <script>
    
    import testNav from './testnavcomponent'
    import landingpage from './landingpage.vue'
    import Navigation from './appbar.vue'
    export default {

  
        components: {
          testNav,
            landingpage,
            Navigation
        },

        data() {
            return {
                toggleMenu: false,
                appTitle: 'Meetamore',
      sidebar: false,
      menuItems: [
          
          { title: 'Game Info', path: '/home', icon: 'mdi-account' },
          { title: 'Character', path: '/home', icon: 'mdi-home' },
          { title: 'News', path: '/home', icon: 'home' },
          { title: 'Community', path: '/home', icon: 'mdi-home' },
          { title: 'Shop', path: '/home', icon: 'mdi-home' },
         
           
     ]
            }
        },
        computed: {
            showMenu() {
                return this.toggleMenu 
            }
        }
    }


    
    
    </script>