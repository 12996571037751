<template>
<div v-if="isMobile">
    <mobile width="100%">
   
      <v-div width="100%">
  
  
  <v-flex row class="ml-6 mr-6 mt-12 pa-4" justify="center" align="center"> 
     <v-col cols="12" justify="left" align="center" class="ma-2 pa-4">
        <div>
  
         <p class="text-h2 pa-3 ma-4" justify="left" align="left"> <strong>Connect on your emmotional level </strong> </p>
  
        </div> 
  
        <div> 
         <p class="text-h5 ma-4 pa-3" justify="left" align="left">
             Embark on a captivating adventure of love, where you'll learn the art of building lasting connections and meaningful relationships while earning badges and impressing your friends.
          </p>
  
        </div>
  
        <div>
     <v-row align="center" justify="center" hidden>
    <v-col cols="5"> 
      
      <v-text-field 
      style ="
  border: 1px solid #F6FDEB;
  border-radius: 50%;
  border: none;
  "
      class="" placeholder="Email Address" outlined clearable>
      
      </v-text-field> 
  </v-col>
  
    <v-col cols="4" >
     <v-btn dark x-large color="grey" 
     class=""
     @click="sendEmailVerification"
  >
      Signup for Pre-Launch 1
  </v-btn>
  </v-col>
  
  
  <v-col cols="2"> 
     <v-btn
     class="ma-2"
  variant="outlined"
  color="Black">  
  <v-icon>mdi-play-box-outline</v-icon>
  Play
  </v-btn>
  </v-col>
  
  
         
             <v-col cols="6"></v-col>
         </v-row>
  
         <!-- new signup form -->
         <div class="subscription">
            <v-col cols="12" sm="12" md="12" > 
  
  <v-text-field
clearable
label="Enter your mail address"  
variant="outlined"
class="pt-4 pr-4 pl-4 ma-0 text-caption"
></v-text-field>
  <v-btn block rounded="xl" size="x-large" class="pa-4 ma-2"
  @click="sendEmailVerification"
  > Signup for Pre-Launch</v-btn>
</v-col>
  </div>
         
        </div>
  
  
     </v-col>
     
  
  
     <v-col cols="12" justify="left" align="center" class="pa-4">
          <v-img class="ma-6" style="height:450px" src="../assets/img13.png"></v-img>
     </v-col>
  
     <v-divider class="" color="blue "></v-divider>
         <!--end of section  1 -->
  </v-flex>
  
  
  <v-flex row  class="ml-6 mr-6 mt-12 pa-4" justify="center" align="center"> 
  
         <v-row> 
  
             <!-- column one -->
             <v-col cols="12"  justify="center" align="center">
                 
                 <v-img  justify="left" align="left" src="../assets/img9.png" class="">
  
                 </v-img>
  
                  <div justify="left" align="left" class="mt-4">
                 <p class="text-h2"> Exciting Gameplay</p>
                
  
             
                 <p class="text-h5">  
                     Travel through space and time to learn and grow your character’s relationship skills.
  
                 </p>
             </div>
             
             </v-col>
  
  
           
  
             <!-- column 2 -->
             <v-col cols="12">
                 <!-- deity image -->
  
                 <v-img height="350px" src="../assets/img8.png"> </v-img>
  
             </v-col>
           
  
         </v-row>
   
     <!-- end of section -->
  </v-flex>
  
  
  <v-divider color="blue"> </v-divider>
  
  <v-row> 
  
     <!-- this row displays the carousel the shows all the 9 AI dieties -->
  <!--
     <v-carousel hide-delimiters align="center" justify="center" class="ma-4 pa-4">
         <v-carousel-item >
  <v-row justify="center" align="center">
  
  
     <v-col cols="2" v-for="(item,i) in items">
        <v-img  
       :key="i"
       :src="item.src"
    />
  </v-col>
  </v-row>
  </v-carousel-item>
   </v-carousel>
  --> 
  
  <v-row  justify="center" align="center" class="mt-8" > 
  
     <!-- new carousel  start-->
  
    
  <v-container>
  
     <p align="center" justify="center" class="text-h4 ma-4 text-weight-regular">Explore 9 AI Deities <!--to guide you on your journey --></p>
    
   <v-sheet hidden
     elevation="0"
     
  
   >
     <v-slide-group
       v-model="model"
       class="pa-4"
       selected-class="#ECEFF1"
       show-arrows
     >
       <v-slide-group-item
         v-for="n in deities"
         :key="n"
         v-slot="{ isSelected, toggle, selectedClass }"
       >
       
           <div class="d-flex align-center justify-center">
  
             <v-img height="250px" width="150" :src='n.src'> </v-img>
      
           </div>
  
           <p class="pa-4 text-h5 ma-4" justify="center" align="center">{{ n.name }}</p>
     
       </v-slide-group-item>
     </v-slide-group>
   </v-sheet>
  
   
    <v-carousel
      cycle
      height="400"
      hide-delimiter-background
      show-arrows="hover"
    >
      <v-carousel-item
        v-for="(n, i) in deities"
        :key="i"
      >
        <v-sheet 
        >
          <div class="d-flex fill-height justify-center align-center">
            <div class="text-h2">
              <div class="d-flex align-center justify-center">
  
  <v-img height="250px" width="250" :src='n.src'> </v-img>
  
  </div>
            </div>
          </div>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
   
  
  
  </v-container>
  
  
  
     <!-- new carousel end -->
  </v-row>
  </v-row>
  
  <v-row align="center" justify="center" class="pa-4 ma-4 mt-12" hidden>
     <p align="center" justify="center" class="text-h4">Explore 9 AI Deities <!--to guide you on your journey --></p>
     </v-row>
  
  
  <v-row justify="center" align="center" height="300px" hidden>
    
  
  
  
         <v-col cols="3" class="diety" justify="center" align="center">
             
             <!--Image goes here -->
             <v-img src="../assets/img7.png" class="dietyImg"> </v-img>
             <!-- caption goes here -->
    
             <p class="dietyCaption font-weight-medium">Sasha</p>
         </v-col>
  
         <v-col cols="3" class="diety" justify="center" align="center">
             <!--Image goes here -->
             <v-img src="../assets/img4.png" class="dietyImg"> </v-img>
             <!-- caption goes here -->
             <p class="dietyCaption font-weight-medium">Visha</p>
         </v-col>
  
         <v-col cols="3" class="diety" justify="center" align="center">
             <!--Image goes here -->
             <v-img src="../assets/img5.png" class="dietyImg"> </v-img>
             <!-- caption goes here -->
             <p class="dietyCaption font-weight-medium">Zita</p>
         </v-col>
  
         <v-col cols="3" class="diety" justify="center" align="center" >
             <!--Image goes here -->
             <v-img src="../assets/img6.png" class="dietyImg"> </v-img>
             <!-- caption goes here -->
             <p class="dietyCaption font-weight-medium">Kali</p>
         </v-col>
   
     </v-row>
  
  <v-row class="mt-12" justify="center" align="center"> 
  
     <v-divider color="blue" class="mb-12"></v-divider>
         
  
  </v-row>
  
  <v-flex cols="12">
  <p class="text-h4 ma-4" > Explore uncharted paths</P> 
  </v-flex>
  
     <v-flex row class=" mb-12" justify="center" align="center"> 
  
     
         <v-col cols="12">
             <!-- avater goes here--> 
             <v-avatar
         color="red"
         size="150"
         rounded="75"
         elevation="2"
       >
       Leader
       </v-avatar>
         </v-col>
         <v-col cols="12">
             <!-- avater goes here--> 
             <v-avatar
         color="black"
         size="150"
         rounded="75"
         elevation="2"
       >
       Conservationist
  
       </v-avatar>
         </v-col>
  
  
         <v-col cols="12">
             <!-- avater goes here--> 
  
             <v-avatar
         color="#00FFFF"
         size="150"
         rounded="75"
         elevation="2"
       >
       Devotee
       </v-avatar>
         </v-col>
  
         <v-col cols="12">
             <!-- avater goes here--> 
  
             <v-avatar
         color="#ECEFF1"
         size="150"
         rounded="75"
         elevation="2"
       >
       And More...
       </v-avatar>
         </v-col>
     </v-flex>
   
  
  <v-divider color="blue"></v-divider>
  
  
  <v-container class="mt-12"> 
     <v-flex row>
  
     <div cols="12"> <p class="text-h4 ma-3 font-weight-strong">MeetAmore</p> </div>
         <v-col cols="12">
            
             <p class="text-h6">Step inside and unlock the power within.</p>
  
  <!-- subscribe input -->
  
  <v-row class="ma-6" hidden>
    <v-col cols="8" align="right" justify="right">  <v-text-field class="" placeholder="Subscribe to our newsletter" outlined clearable></v-text-field> </v-col>
  
    <v-col cols="4"  justify="left" align="left" >
     <v-btn dark x-large color="grey" justify="left" align="left" class=" pt-6 pb-8"> Subscribe </v-btn>
  </v-col>
  
  
  </v-row>
  
  <div class="">
   
  
  <!-- test start -->
  
  <v-col cols="12" sm="12" md="12" > 
  
          <v-text-field
    clearable
    label="Enter your mail address"  
    variant="outlined"
    class=""
  ></v-text-field>
          <v-btn block rounded="xl" @click="sendEmailVerification" size="x-large" class="pa-4 ma-2"> Signup for Pre-Launch</v-btn>
  </v-col>
  
  <!-- close of-->
  
  <!-- test end -->
  
  
  </div>
  
  
  <v-row>
  
  </v-row>
  
         </v-col>
         <v-col cols="12"> 
  
             <v-img height="350px" src="../assets/img14.png"> </v-img>
         </v-col>
     </v-flex>
  </v-container>
  </v-div>
    </mobile>
  </div>
   
  
  
  <!-- thank you dialog start-->
  
  
  <v-dialog width="500" style="background-color:#536872; text=white;opacity: 0.99;">
    <template v-slot:activator="{ props }">
      <v-btn text="Open Dialog" hidden> </v-btn>
    </template>
  
    <template v-slot:default="{ isActive }">
      <v-card title="Welcome 🥳" >
        <v-card-text>
  
  <p class="text-h6"  > 
  
  
      Thank you for subscribing to MeetAmore prelaunch! You will be 
  notified when we launch our app. 
  
  </p>
  
      </v-card-text>
  
        <v-card-actions>
          <v-spacer></v-spacer>
  
          <v-btn
            text="Close"
            @click="isActive.value = false"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
  
  
  <!-- thank you modal end -->
  </template>
  
  <script>
  
  import { auth } from "../firebaseConfig";
  import {  sendSignInLinkToEmail } from "firebase/auth";
  
  
  import image1 from '../assets/img1.png'
  import image2 from '../assets/img2.png'
  import image3 from '../assets/img3.png'
  import image4 from '../assets/img4.png'
  import image5 from '../assets/img5.png'
  import image6 from '../assets/img6.png'
  import image7 from '../assets/img7.png'
  import image8 from '../assets/img8.png'
  import image9 from '../assets/img9.png'
  
  export default {
   
      computed: {
     isMobile() {
     if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
       return true
     } else {
       return false
     }
   },
   
  },
  
  data(){
      return {
  
          //create a list of image urls 
          // upload website to firebase hosting 
          // make the app mobile friendly 
          // add firebase function to collect information 
          // add all dieties to the site 
  
          model: null,
          deities: [
              {
                  src:image8,
                  name:'Zita'
              },
              {
                  src:image6,
                  name:'Visha'
              },
              {
                  src:image5,
                  name:'Sasha'
              },
              {
                  src:image4,
                  name:'Kali'
              },
              {
                  src:image5,
                  name:'Zuri'
              },
              {
                  src:image6,
                  name:'Zita'
              },
              {
                  src:image7,
                  name:'Zita'
              },
              {
                  src:image6,
                  name:'Zita'
              },
              {
                  src:image8,
                  name:'Zita'
              }
          ],
          items: [
            {
              src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
            },
            {
              src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
            },
            {
              src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
            },
            {
              src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
            },
          ],
  
      }
  },
  
  methods: {
      sendEmailVerification(){
         // alert('thank you for signing up!');
  
         const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: 'https://getmeetamore.web.app',
    // This must be true.
    handleCodeInApp: true
  };
  
  
  sendSignInLinkToEmail(auth, 'isaacnkyalo@gmail.com', actionCodeSettings)
    .then(() => {
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem('emailForSignIn', email);
      //show the user a modal thanking them for subscribbing 
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log('Boo hoo '+errorMessage + 'error code '+errorCode)
      // ...
    });
      }
  }
  
  }
  
  </script>
  
  <style>
   
  .subscription *{
    margin: 4;
    padding: 0;
    outline: 0;
  }
  .subscription{
   
    display: inline-block;
  }
  .subscription input{
  }
  
  .subscription input[type="submit"]:hover{
   /* background-color: #F5FF00;  */
  }
  
  .diety {
      height: 350px;
      padding: 5px
  }
  .dietyImg {
      height: 250px;
      padding: 5px;
  }
  .dietyCaption {
      padding: 5px;
      margin: 5px;
  }
  </style>
  
  