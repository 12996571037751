// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
//import { getStorage, ref,getDownloadURL } from "firebase/storage";

import { getAuth } from "firebase/auth";


//import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';



//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCGP-WORBY2K9wgxwkKLrpo4l-67hRBG-g",
    authDomain: "getmeetamore.firebaseapp.com",
    databaseURL: "https://getmeetamore-default-rtdb.firebaseio.com",
    projectId: "getmeetamore",
    storageBucket: "getmeetamore.appspot.com",
    messagingSenderId: "770282221748",
    appId: "1:770282221748:web:075012d6586ddfa51927e1",
    measurementId: "G-PG2CMTEX8L"
  };

// start



//end 

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db= getFirestore(app);
const auth  = getAuth(app);
//const storage = getStorage();
//const analytics = getAnalytics(app);

export  { auth,db}