<template>
  <v-app>
    <v-main>

   <Header/> 

      <!-- 
make the design mobile friendly 
add an app for xxlarge screen 
 3 * 25 TT

 #2 
 connect app to firebase email auth 
 get email verification 
 6 * 25 

 #3 
 add links to all the necessary places 
 ( Peace ✌️ ✌️ ✌️)
 - 

      -->
    <landingpage/>
    <Footer/>
    </v-main>
  </v-app>
</template>

<script>
import Navigation from './components/appbar.vue'
import Header from './components/header.vue'
import Footer from './components/footer.vue'
import landingpage from './components/landingpage.vue';

export default {
  name: 'App',

  components: {
    Navigation,
    Header,
    Footer
  },

  data: () => ({
    //
  }),
}
</script>
