<template>


<!--desktop view start-->
<div v-if="!isMobile">
  <desktop>
 
    <v-div>

        <!-- footer level 1 start-->
        <v-footer border color="#1F0249">
<v-row class="pa-4 ma-4"> 
 
        <v-col cols="2" align="center" justify="center"> 
<div elevation="0">
<div class="justify" align="center">
    <p class="text-h4">MeetAmore</p>
<p> Subscribe to our newsletter </p>
<v-btn
variant="outlined"
color="Black" 
> 
Subscribe 
</v-btn>
<p>Get product updates, company news and more </p>
</div>
</div>
</v-col>

<v-col cols="2" class="mt-4">
            <p> Products </p>
            <p> Features </p>
            <p> Security </p>
            <p> Team </p>
            <p> Enterprice  </p>
            <p> Customer Stories </p>
            <p> Customer Stories </p>
            
 </v-col>

<v-col cols="2">
            <p> Platform</p>
            <p> Developer API</p>
            <p> Partners </p>
            <p> Election</p>
</v-col>

<v-col cols="2">
            <p> Support </p>
            <p> Docs </p>
            <p> Community Forums</p>
            <p> Proffessional Services </p>
            <p> Skills </p>
            <p> Status </p>
            <p> Social Media </p>
            <p> Links</p>
</v-col>

<v-col cols="2">
            <p> Company</p>
            <p> About</p>
            <p> Blog</p>
            <p> Career</p>
            <p> Press</p>
            <p>Inclusion </p>
            <p>Social I</p>
</v-col>
 
</v-row>
</v-footer>
<!-- footer level one end -->

<!-- footer level 2 start -->

<v-footer border color="#1F0249" >
    <v-row row justify="center" align="center" class="ma-8"> 
       
   <p class="ma-4"> Meetamore @ {{currentDate.getFullYear()}} </p>
   <p class="ma-4"> Legal </p>
   <p class="ma-4">Privacy</p>
   <P class="ma-4">Terms </P>
   <p class="ma-4"> Cookies</p>
 

</v-row>
</v-footer>

<!-- footer level 2 end -->

<!-- footer level 3 start-->

<v-footer border color="#1F0249"> 
    <v-row justify="center" align="center" class="ma-8">
        <v-col cols="12">
<!-- Image one -->
        </v-col>

        <v-col cols="4">
            <!-- image two-->
            <v-img height="150" src="../assets/rating.png"></v-img>
        </v-col>

        <v-col cols="6">
            <!-- image 3 or content -->
         <p>  Strong Sexual Content</p> 
         <p> Intense Violence</p> 
         <p> Strong Language</p> 
         <p>Nudity</p> 
         <p>In-Game Purchases</p> 
         <p>Users Interact</p> 
        </v-col>

    </v-row>
</v-footer>
        </v-div>
        </desktop>
        </div>
<!-- desktop view end -->


<!-- mobile view start -->


<div v-else>
  <mobile>
 
    <v-div>
        <v-footer border color="#1F0249">
<v-flex row class="pa-4 ma-4"> 

  
        <v-col cols="12" align="center" justify="center" class="pa-2"> 

<div elevation="0">
<div class="justify" align="center">
    <p class="text-h4">MeetAmore</p>
<p> Subscribe to our newsletter </p>
<v-btn
variant="outlined"
color="Black" 
> 
Subscribe 
</v-btn>
<p>Get product updates, company news and more </p>
</div>
</div>
</v-col>

        <v-col cols="12" class="mt-4">
            <p> Products </p>
            <p> Features </p>
            <p> Security </p>
            <p> Team </p>
            <p> Enterprice  </p>
            <p> Customer Stories </p>
            <p> Customer Stories </p>
            
        </v-col>

        <v-col cols="12">
            <p> Platform</p>
            <p> Developer API</p>
            <p> Partners </p>
            <p> Election</p>


        </v-col>

        <v-col cols="12">
            <p> Support </p>
            <p> Docs </p>
            <p> Community Forums</p>
            <p> Proffessional Services </p>
            <p> Skills </p>
            <p> Status </p>
            <p> Social Media </p>
            <p> Links</p>
        </v-col>

        <v-col cols="12">
            <p> Company</p>
            <p> About</p>
            <p> Blog</p>
            <p> Career</p>
            <p> Press</p>
            <p>Inclusion </p>
            <p>Social I</p>
        </v-col>

         
 
</v-flex>



</v-footer>


<v-footer border color="#1F0249" >
    <v-flex row justify="center" align="center" class="ma-8"> 
        <v-col cols="12">
   <p class="ma-4"> Meetamore @ {{currentDate.getFullYear()}} </p>
   <p class="ma-4"> Legal </p>
   <p class="ma-4">Privacy</p>
   <P class="ma-4">Terms </P>
   <p class="ma-4"> Cookies</p>
</v-col>
</v-flex>
</v-footer>

<v-footer border color="#1F0249"> 
    <v-flex row justify="center" align="center" class="ma-8">
        <v-col cols="12">
<!-- Image one -->
        </v-col>

        <v-col cols="12">
            <!-- image two-->
            <v-img src="../assets/rating.png"></v-img>
        </v-col>

        <v-col cols="12">
            <!-- image 3 or content -->
         <p>  Strong Sexual Content</p> 
         <p> Intense Violence</p> 
         <p> Strong Language</p> 
         <p>Nudity</p> 
         <p>In-Game Purchases</p> 
         <p>Users Interact</p> 
        </v-col>

    </v-flex>
</v-footer>
        </v-div>
        </mobile>
        </div>

<!-- mobile view end -->

 


</template>



<script>

export default {

    
    computed: {
   isMobile() {
   if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
     return true
   } else {
     return false
   }
},
    },
 

    data() {
        return {
            currentDate: new Date,
        }
    }
}
</script>